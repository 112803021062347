import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BoldText } from '../common/Text';

import fitcoinIconWhite from '../../images/common/rewardiconwhite.png';
import fitcoinIcon from '../../images/common/rewardicon.png';
import starIcon from '../../images/home/fitcoin-star.svg';
import { fontSize } from '../../utils';

const FitcoinBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  background: linear-gradient(266.12deg, #ad8223 5.77%, #e5d776 115.99%);
  border-radius: 28px;
  padding: 7px 10px;
  padding-right: 12px;
`;

const EarnText = styled(BoldText)`
  color: white;
  font-size: ${() => `${0.6875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const EarnIcon = styled.img`
  width: 0.6875rem;
  margin: 0 0.25rem;
`;

const Icon = styled.img`
  width: 1rem;
  margin: 0 0.5rem;
`;

const BalanceIcon = styled(Icon)`
  width: 1.1rem;
`;

const BalanceText = styled(EarnText)`
  font-size: 16px;
  line-height: 24px;
  margin-left: 11px;
  font-weight: semibold;
`;

const EarnBadge = ({ className }) => (
  <FitcoinBadgeContainer className={className}>
    <EarnText>Earn</EarnText>
    <EarnIcon src={fitcoinIconWhite} alt="Fitcoin" />
  </FitcoinBadgeContainer>
);

const FitcoinIcon = () => <Icon src={fitcoinIcon} />;

const BalanceBadge = ({ balance }) => (
  <FitcoinBadgeContainer>
    <img src={starIcon} alt="star" />
    <BalanceText>{balance}</BalanceText>
  </FitcoinBadgeContainer>
);

BalanceBadge.propTypes = {
  balance: PropTypes.string.isRequired,
};

export {
  FitcoinBadgeContainer as FitcoinContainer,
  EarnBadge,
  FitcoinIcon,
  BalanceBadge,
};
