import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import BackButtonIcon from '../../images/common/back-button.svg';
import { withRouter } from 'react-router-dom';
import initializeFreshDesk from '../../utils/freshchat';
import supportIcon from '../../images/common/head-gear.svg';

const MainContainer = styled.div`
  width: 100%;
  max-height: 102px;
  padding: 20px 20px 20px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #f0f3f6;
  background: #ffffff;
`;

const Heading = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0f0b28;
  margin-left: 4px;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const NeedHelpText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #714fff;
  margin-left: 4px;
`;

class CommonHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  render() {
    return (
      <MainContainer>
        <HeaderLeft>
          <div
            onClick={() => {
              if (this.props.onBack) {
                this.props.onBack();
              } else {
                this.props.history.goBack();
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={BackButtonIcon}
              width={'32px'}
              height={'32px'}
              alt={'back-button'}
            />
          </div>
          <div>
            <Heading>{this.props.heading}</Heading>
          </div>
        </HeaderLeft>
        {this.props.showSupport && (
          <HeaderRight
            onClick={() => {
              this.props.showSupportOnClick();
            }}
          >
            <img src={supportIcon} width={'16px'} height={'16px'} alt="help" />
            <NeedHelpText>Need help ?</NeedHelpText>
          </HeaderRight>
        )}
      </MainContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

export default withRouter(connect(mapStateToProps)(CommonHeader));
