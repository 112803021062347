import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { FaChevronRight } from 'react-icons/fa';

import {
  RegularText,
  BoldText,
  ButtonText,
} from '../../components/common/Text';
import Header from '../../components/common/Header';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../../components/common/StyledTappable';
import PrescriptionStatusCard from '../../components/lab-test/StatusCard';
import Carousel from '../../components/common/Carousel';
import {
  getAvailableBalance,
  getLabOrders,
  getDigitsationStatus,
  getBottomCartText,
  getLabTestsCart,
} from '../../services/lab-test';

import { removeLabTest } from '../../services';
import { logEvent } from '../../utils/logEvent';

// import searchIcon from '../../images/lab-test/search.png';
import modalIcon from '../../images/lab-test/modal-icon.svg';
import unserviceableIcon from '../../images/lab-test/unserviceable.svg';
import { PrimaryButton } from '../../components/common/Buttons';
import LoadingComponent from '../../components/common/LoadingComponent';
import kidneyIcon from '../../images/lab-test/kidney.png';
import uploadIcon from '../../images/common/upload.svg';
import arrowLeft from '../../images/common/arrow-left.svg';
import cartIcon from '../../images/lab-test/cart.svg';
import orderIcon from '../../images/lab-test/bag.svg';
import StarIcon from '../../images/lab-test/star_lab_logo.png';
import arrowDown from '../../images/lab-test/arrow-down.svg';
import locationIcon from '../../images/common/location-fill.svg';
import searchIcon from '../../images/consultations/new-flow/search.svg';
import { initializeAmplitudeClient } from '../../services/analytics';
import HelpIcon from '../../images/star-health-status/help.svg';
import { setHealthId } from '../../actions';
import { selectPatient } from '../../services';
import CommonHeader from '../../components/common/CommonHeader';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 999;
  background: #ffffff;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const CarouselContainer = styled.div`
  margin: 0 -0.5rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  align-self: stretch;
  margin-top: 1rem;
  overflow-x: hidden;
`;

const BannerImageContainer = styled.div`
  width: ${(props) => props.width};
  flex-shrink: 0;
  padding: 0 0.25rem;
`;

const BannerImage = styled.img`
  width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  flex-direction: column;
  align-items: stretch;
  margin-top: 1rem;
`;

const OrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  padding-top: 0px;
  align-self: stretch;
  align-items: stretch;
`;

const UnserviceableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`;

const UnserviceableLogo = styled.img`
  max-width: 12rem;
  margin-bottom: 1.375rem;
`;

const UnserviceableTitle = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-bottom: 1rem;
`;

const UnserviceableBody = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
  color: #808080;
  margin-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WhiteLocationIcon = styled(IoMdInformationCircleOutline)`
  color: white;
  margin-right: 0.75rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const ModalIcon = styled.img`
  width: 5.7rem;
`;

const ModalText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const AddedTestsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 29px 24px;
  flex-shrink: 0;
`;

class LabUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      banners: [],
      selectedCity: null,
      modalVisible: false,
      locationUnserviceable: false,
      loading: true,
      packages: [],
      disableBack: false,
      payableByUser: 0,
      bottomTextRight: 'View Cart',
      bottomTextLeft: '',
      cartDetails: [],
      showButton: false,
      cartId: null,
      cart: [],
      showLabPackages: false,
      countOfItems: {},
      covidBanners: [],
      packageBanners: [],
      policyId: null,
      healthId: '',
      availableBalance: 0,
      selectedPatientId: '',
      option: 'option2',
      // policyId: null,
    };
    const { state } = props.location;
    console.log(state, 'The state recieved');
    if (state && state.city && state.disableBack) {
      this.state.selectedCity = state.city;
      this.disableBack = state.disableBack;
    }
    if (state && state.patientId) {
      this.state.selectedPatientId = state.patientId;
    }
    this.state.healthId = this.props.selectedHealthId;
    // if (state.policyId) {
    //   console.log(state.policyId);
    //   this.state.policyId = state.policyId;
    // }
  }

  componentDidMount = () => {
    logEvent('Labs Home Screen');
    this.getCart();
    if (this.state.option) {
      this.getLabOrders(this.props.authToken);
    } else {
      this.getOrders();
    }
    this.getNativeGeolocation();
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
  };

  selectPatientForStarHealth(cartId) {
    if (this.state.selectedPatientId) {
      selectPatient(cartId, this.state.selectedPatientId, this.props.authToken)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return;
  }

  getOrders() {
    const { authToken } = this.props;
    console.log(this.props.selectedHealthId, 'hello');
    getAvailableBalance(authToken, this.state.healthId)
      .then((res) => {
        this.state.availableBalance = res.availableBalance;
        if (res.availableBalance === 0) {
          logEvent('No Balance');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.getLabOrders(authToken);
      });
  }

  getErrorMessage() {
    return (
      <span className="text-center text-body-s">
        You have exhausted your policy balance for Health Checkup
      </span>
    );
  }

  getLabOrders(authToken) {
    getLabOrders(authToken)
      .then((res) => {
        const {
          cards,
          banners,
          showLabSearch,
          showLabUploadRx,
          orderUpdatesCount,
          packages,
          showLabPackages,
          covidBanners,
        } = res;
        this.setState({
          orders: cards,
          banners,
          showLabSearch,
          showLabUploadRx,
          orderUpdatesCount,
          packages,
          showLabPackages,
          packageBanners: res.packageBanners || [],
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getNativeGeolocation() {
    console.log('test');
    if (window.Android && window.Android.getLocationPermissions) {
      return window.Android.getLocationPermissions();
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'GET_LOCATION_PERMISSIONS',
        })
      );
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'getLocationPermissions',
        })
      );
    }
  }

  getCart() {
    const { authToken } = this.props;
    getLabTestsCart(authToken)
      .then((res) => {
        let { cart, cartId, countOfItems } = res;
        this.selectPatientForStarHealth(cartId);
        this.setState({
          cartId,
          cart,
          countOfItems,
          showButton:
            countOfItems?.packageCount > 0 || countOfItems?.labTestCount > 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  openOrder(order) {
    const {
      cardType: type,
      orderId,
      digitisationId,
      cartId,
      reimbursementId,
      status,
      testsType,
    } = order;
    const { history, authToken } = this.props;
    // console.log(orderId)
    history.push('/lab-test/order-status', {
      orderId: orderId,
      digitisationId: digitisationId,
      cartId: cartId,
    });
    // if (type === 'booked-order') {
    //   logEvent('Labs Dashboard Order Booked Card', {
    //     orderId,
    //   });
    //   if (status === 'payment-pending') {
    //     return history.push('/lab-test/copay', {
    //       orderId,
    //       goBack: true,
    //     });
    //   } else {
    //     history.push('/lab-test/track', {
    //       orderId,
    //       goBack: true,
    //     });
    //   }
    // } else if (type === 'pending-digitisation') {
    //   logEvent('Labs Report Pending Digitization Card Click');
    //   // switch (action) {
    //   //   case 'openOrder': {
    //   getDigitsationStatus(digitisationId, authToken).then((res) => {
    //     console.log(res);
    //     const { message } = res;
    //     if (message === 'success') {
    //       history.push('/lab-test/upload-success', {
    //         res,
    //         goBack: true,
    //       });
    //       return;
    //     }
    //   });
    //     break;
    //   }
    //   case 'payment': {
    //     history.push('/lab-test/order-summary', {
    //       cartId,
    //       paymentNext: true,
    //     });
    //     break;
    //   }
    //   default: {
    //     break;
    //   }
    // }
    // } else if (type === 'available-cart') {
    //   logEvent('Labs Prescription Visit Doctor Recommended Test', {
    //     cartId: cartId,
    //   });
    //   // logEvent('Labs Available Carts',{
    //   //   cartId: cartId
    //   // });
    //   history.push('/lab-test/review-and-book', {
    //     cartId: cartId,
    //     testsType,
    //   });
    // } else if (type === 'reimbursement') {
    //   logEvent('Labs Reimbursement Status Card Click');
    //   history.push('/lab-test/reimbursement-detail', {
    //     reimbursementId: reimbursementId,
    //   });
    // }
  }

  goToAllOrderPage = () => {
    const { history } = this.props;

    history.push('/lab-test/my-orders', {
      goBack: true,
    });
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  hideModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  selectCity = () => {
    const { history } = this.props;
    history.replace('/lab-test/select-city', {
      forwardRoute: '/lab-test',
    });
  };

  openPackage = (ele) => {
    logEvent('Labs Package Selected', {
      packageId: ele.packageId,
      packageName: ele.packageName,
    });
    if (ele.type === 'test-banner') {
      this.props.history.replace(`/lab-test/covid-package/${ele.id}`, {});
    } else if (ele.type === 'package-banner') {
      this.props.history.replace(`/lab-test/package-details`, {
        packageId: ele.packageId,
      });
    }
    logEvent('Covid Package Selected', {
      packageId: ele.id || ele.packageId,
    });
  };

  raiseTicket = () => {
    if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'MAIL_TO',
          email: 'bookings.starhealth@getvisitapp.com',
          subject: 'Get help for labs',
          url: 'mailto:bookings.starhealth@getvisitapp.com?subject=Get help for labs&body=',
        })
      );
    } else {
      window.open(
        `mailto:bookings.starhealth@getvisitapp.com?subject=Get help for labs&body=`
      );
    }
  };

  render() {
    if (this.state.availableBalance < 500 && this.state.cart.length > 0) {
      const { authToken } = this.props;
      this.state.cart.forEach((value) => {
        this.setState((state) => {
          return {
            showButton: false,
          };
        });
        removeLabTest(value.cartItemId, this.state.cartId, authToken);
      });
      this.setState(() => {
        return {
          cart: [],
        };
      });
    }

    const {
      orders,
      banners,
      modalVisible,
      locationUnserviceable,
      showLabSearch,
      showLabUploadRx,
      loading,
      orderUpdatesCount,
      packages,
      countOfItems,
      showLabPackages,
      covidBanners,
      packageBanners,
      option,
    } = this.state;
    const { history } = this.props;
    console.log(option, showLabUploadRx, 'this is it');

    const renderOrder = (ele) => {
      return (
        <PrescriptionStatusCard
          key={ele.orderId || ele.requestId || ele.digitisationId}
          orderRequest={ele}
          onTap={() => this.openOrder(ele)}
        />
      );
    };

    const renderPackages = (ele) => {
      const fontColor = ele.fontColor;
      const bgColor = ele.bgColor;
      const image = ele.imageUrl;
      return (
        <div
          className={`mt-8px hover mb-8px rounded-12 pt-16px pl-16px flex flex-col justify-between flex-dual`}
          style={{
            height: '188px',
            background: bgColor ? `#${bgColor}` : '#D3C5FB',
            color: fontColor ? `#${fontColor}` : '#26188C',
          }}
          onClick={() => {
            this.props.history.push('/lab-test/package-details', {
              packageId: ele.packageId,
              patientId: this.state.selectedPatientId,
            });
          }}
        >
          <div className="pr-20px">
            <p className="text-title-m font-bold">{ele.packageName}</p>
            <p className="text-body-s font-regular mt-4px">
              Include {ele.countOfTests} Tests
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <div className="pb-8px">
              <p className="text-body-s font-semibold">Starts from</p>
              {/* <div className="flex flex-row justify-around"> */}
              <p className="text-title-m2 font-semibold">₹ {ele.offerPrice}</p>
              {/* <p className="text-title-m2 font-semibold pl-6px">₹ 0</p> */}
              {/* </div> */}
            </div>
            <img src={StarIcon} style={{ height: '56px' }} alt="package" />
          </div>
        </div>
      );
    };

    return (
      <OuterContainer>
        <CommonHeader
          onBack={() => {
            if (this.disableBack) {
              this.props.history.replace('/home');
            } else {
              this.props.history.goBack();
            }
          }}
          heading={'Lab Tests'}
          showSupport
          showSupportOnClick={this.raiseTicket}
        />

        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <BodyContainer>
              {banners.length > 0 && (
                <CarouselContainer>
                  <Carousel autoplay showDots delay={5000}>
                    {banners.map((ele, idx) => (
                      <BannerImageContainer
                        key={idx}
                        width={`${
                          Math.min(document.body.clientWidth, 800) * 0.9
                        }px`}
                      >
                        <BannerImage src={ele} />
                      </BannerImageContainer>
                    ))}
                  </Carousel>
                </CarouselContainer>
              )}
              {option === 'option2' && showLabSearch && (
                <div
                  className="bg-txtsecondary-shades4 bg-opacity-50 rounded-12"
                  style={{ margin: 0, width: '100%', marginBottom: '32px' }}
                  onClick={() => history.push('/lab-test/search')}
                >
                  <div className="order-status-details">
                    <div className="lab-order-items">
                      <div
                        className="text-body-l text-txtsecondary"
                        style={{ display: 'flex' }}
                      >
                        <img
                          src={searchIcon}
                          alt="search-icon"
                          style={{ marginRight: '11px' }}
                        />
                        Search for lab tests
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {option === 'option2' && (
                <div className="mb-32px">
                  <div
                    className="text-center flex flex-col hover items-center p-20 border border-txtsecondary-shades7	rounded-12"
                    onClick={() => {
                      logEvent('Labs Upload Prescription Button Click');
                      history.push('/lab-test/upload-prescription');
                    }}
                  >
                    <img
                      src={uploadIcon}
                      alt="upload"
                      className="mb-16 h-px-30 w-25"
                    ></img>
                    <span className="text-body-s font-semibold max-w-75%">
                      Upload your prescription to book prescribed lab tests.
                    </span>
                  </div>
                </div>
              )}
              {/* <div
                className="bg-txtsecondary-shades4 bg-opacity-50 rounded-12 mb-32px"
                style={{ width: '100%' }}
                onClick={() => {
                  this.goToAllOrderPage();
                }}
              >
                <div className="order-status-details">
                  <div className="lab-order-items">
                    <div className="txt-black-600-13">
                      My Lab Tests / Reports
                    </div>
                    <FaChevronRight className="float-right" />
                  </div>
                  {parseInt(orderUpdatesCount) > 0 && (
                    <div className="txt-blue-500-12 mt-4">
                      {orderUpdatesCount} Update
                    </div>
                  )}
                </div>
              </div> */}

              {packageBanners.length > 0 ? (
                <div className="flex flex-row flex-wrap justify-between items-start">
                  {/* {packageBanners.map((ele, idx) => {
                    return (
                      <img
                        src={ele.banner_image_url}
                        key={ele.id || ele.packageId}
                        alt="package"
                        onClick={() => this.openPackage(ele)}
                        className="hover pt-16px"
                        style={
                          ele.banner_image_orientation === 'vertical'
                            ? {
                                width: '48%',
                                maxWidth: '48%',
                                flex: 'auto',
                              }
                            : {
                                width: '100%',
                              }
                        }
                      />
                    );
                  })} */}
                </div>
              ) : (
                ''
              )}

              {option === 'option2' && orders.length > 0 ? (
                <div className="pt-14px w-full">
                  <p className="text-black text-body-l font-semibold">
                    Ongoing - Current Status
                  </p>
                  <OrdersContainer>{orders.map(renderOrder)}</OrdersContainer>
                </div>
              ) : (
                ''
              )}
              {option !== 'option2' &&
              showLabPackages &&
              packages &&
              packages.length > 0 ? (
                <div className="self-start w-full">
                  <p className="text-body-l font-bold text-txtlight">
                    Health Packages
                  </p>
                  <div className="flex flex-row flex-wrap justify-between mt-12px">
                    {this.state.availableBalance < 500
                      ? this.getErrorMessage()
                      : packages
                          .filter(
                            (element) =>
                              element.offerPrice <= this.state.availableBalance
                          )
                          .map(renderPackages)}
                  </div>
                </div>
              ) : (
                ''
              )}
            </BodyContainer>
            {this.state.showButton && this.state.cartId && (
              <div className="m-20px mt-40px bg-opacity-0 bg-transparent">
                <div
                  className="pl-16px pr-16px rounded-12 hover pt-18px pb-18px text-button-s font-semibold text-textbox text-center flex flex-row justify-between items-center"
                  style={{
                    background: '#013ADE',
                    backdropFilter: 'blur(4px)',
                    minWidth: '100%',
                  }}
                  onClick={() => {
                    this.props.history.push('/lab-test/review-cart', {
                      cartId: this.state.cartId || this.cartId,
                    });
                    // this.props.history.push('/lab-test/lab-select', {
                    //   cartId: this.state.cartId,
                    // });
                  }}
                >
                  <span className="flex flex-col items-start">
                    <p className="text-body-l text-txtsecondary text-textbox">
                      {countOfItems?.packageCount
                        ? `${countOfItems.packageCount} Package`
                        : ''}
                      {countOfItems?.addOnTestCount
                        ? ` + ${countOfItems.addOnTestCount} add-on`
                        : ''}
                      {countOfItems?.packageCount && countOfItems?.labTestCount
                        ? ' + '
                        : ''}

                      {countOfItems?.labTestCount
                        ? `${countOfItems.labTestCount} test`
                        : ''}
                    </p>
                  </span>
                  <span className="flex flex-row items-center">
                    <p className="mr-8px text-body-l font-semibold text-textbox">
                      {'View Cart'}
                    </p>
                    <img src={cartIcon} alt="cart" />
                  </span>
                </div>
              </div>
            )}
          </>
        )}
        {modalVisible && (
          <Modal>
            {locationUnserviceable ? (
              <UnserviceableContainer>
                <UnserviceableLogo src={unserviceableIcon} />
                <UnserviceableTitle>
                  We are not available at your location yet
                </UnserviceableTitle>
                <UnserviceableBody>
                  Please change your location to see cities where you can book
                  lab tests
                </UnserviceableBody>
                <PrimaryButton onTap={this.selectCity}>
                  <ButtonContainer>
                    <WhiteLocationIcon />
                    <ButtonText>CHANGE LOCATION</ButtonText>
                  </ButtonContainer>
                </PrimaryButton>
              </UnserviceableContainer>
            ) : (
              <ModalContainer>
                <ModalBody>
                  <CloseContainer onTap={this.hideModal}>
                    <FaTimes />
                  </CloseContainer>
                  <ModalIcon src={modalIcon} />
                  <ModalText>
                    Only tests prescribed by VISIT specialists are covered under
                    VISIT OPD Insurance. You can book prescribed tests from
                    “Visit Prescriptions” section.
                  </ModalText>
                </ModalBody>
              </ModalContainer>
            )}
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  insured: state.app.config.insured,
  selectedHealthId: state.user.selectedHealthId,
});

export default connect(mapStateToProps)(LabUpload);
