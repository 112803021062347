import { useState } from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipBody = styled.div`
  width: 189px;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-100%);
  padding: 8px 12px 16px 16px;
  background-color: #f4f0ff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  top: -60px;
  color: #4e4e4e;
  line-height: 1;
  &:before {
    content: ' ';
    left: 92%;
    top: 90%;
    border-top: solid transparent;
    border-bottom: solid transparent;
    border-left: solid transparent;
    border-right: none;
    border-top-color: #f4f0ff;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: 6px;
    background: #f4f0ff;
    transform: rotate3d(-2, 1, 0, -180deg);
  }
`;

const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);
  const showTooltip = (e) => {
    e.stopPropagation();
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 100);
  };
  const hideTooltip = (e) => {
    e.stopPropagation();
    clearInterval(timeout);
    setActive(false);
  };
  return (
    <TooltipContainer onClick={!active ? showTooltip : hideTooltip}>
      {props.children}
      {active && <TooltipBody>{props.content}</TooltipBody>}
    </TooltipContainer>
  );
};

export default Tooltip;
